<template>
  <vx-card class="mt-base">
    <div id="productCategoryTable" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="productData"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
        >
        <!-- search
        @search="handleSearch" -->
        <div slot="header" class="flex flex-col sm:flex-row items-start sm:items-center justify-between w-full mb-5">
          <p class="text-xl font-semibold w-full">Product List</p>
          <div class="flex flex-col sm:flex-row justify-end sm:flex-grow items-center gap-4 w-full">
            <!-- <vs-input
              icon="search"
              class="w-full sm:w-auto flex items-center"
              placeholder="Search product"
              v-model="dataTableParams.search"
            /> -->
            <div class="con-input-search vs-table--search">
              <input v-model="dataTableParams.search" type="text" class="input-search vs-table--search-input">
              <i class="vs-icon notranslate icon-scale material-icons null">search</i>
            </div>
            <vs-button
              class="w-full sm:w-auto"
              @click="addProductPopup = true"
            >Add Product</vs-button>
            <!-- <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="w-full sm:w-24"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select> -->
          </div>
        </div>

        <template slot="thead">
          <vs-th>Sort</vs-th>
          <vs-th>Product Name</vs-th>
          <vs-th>Supplier's Name</vs-th>
          <vs-th>Cost</vs-th>
          <!-- <vs-th>Stock</vs-th> -->
          <vs-th v-if="!isParentCategory">Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <draggable :list="data" @change="updateOrderNumber(data)" tag="tbody" :disabled="isDragDisabled">
            <tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <td>
                  <div class="flex items-center justify-start gap-2">
                    <span v-if="!isDragDisabled" class="cursor-pointer"><vs-icon icon="reorder" size="small" class="text-primary2"></vs-icon></span>
                    <vs-input
                      ref="orderInput"
                      class="no-spin w-auto"
                      color="success"
                      v-model="tr.productDetails.orderId"
                      min="1"
                      :max="productData.length"
                      type="number"
                      @input="handleFieldSort(tr, indextr)"
                    />
                  </div>
                </td>
                <td :data="data[indextr].productDetails.name">
                  <router-link
                    exact
                    class="text-black"
                    :to="handleRedirectToProduct(tr.productDetails._id)"
                    target="target"
                  >
                    {{
                      data[indextr].productDetails.name
                    }}
                  </router-link>
                </td>
                <td :data="data[indextr].productDetails.supplierDetails[0].name">
                  {{
                  data[indextr].productDetails.supplierDetails[0].name
                  }}
                </td>
                <td :data="data[indextr].productDetails.productType">
                  {{
                  data[indextr].productDetails.price ? `$${(data[indextr].productDetails.price / 100).toFixed(2)}` : null
                  }}
                </td>
                <!-- <td :data="data[indextr].productDetails.deletedAt">
                  <p v-if="data[indextr].productDetails.deletedAt == null">In Stock</p>
                  <p v-else>Out of Stock</p>
                </td> -->
                <td :data="data[indextr].productDetails._id" v-if="!isParentCategory">
                  <vx-tooltip text="Remove Product" position="left">
                    <vs-button
                      
                      type="border"
                      size="small"
                      color="danger"
                      @click="openDeletePopup(data[indextr].productDetails)"
                      icon-pack="feather"
                      icon="icon-trash"
                      class="mt-1"
                      
                    ></vs-button>
                  </vx-tooltip>
                </td>
                <vs-popup :active.sync="popUpDelete" title="Remove Product from Category">
                  <p align="center">Are you sure you want to remove this Product from Category?</p>
                  <br />
                  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" vs-mt="6">
                    <vs-col vs-type="flex" vs-align="left" vs-w="6">
                      <div class="w-full m-5" align="right">
                        <vs-button
                          size="lg"
                          color="primary"
                          @click="deleteDetailHandler(popupData)"
                        >Yes</vs-button>
                      </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-align="left" vs-w="6">
                      <div class="w-full m-5">
                        <vs-button size="lg" color="danger" @click="popUpDelete=false">Cancel</vs-button>
                      </div>
                    </vs-col>
                  </vs-row>
                </vs-popup>
              </tr>
          </draggable>
        </template>
      </vs-table>
      <vs-popup id="addProductPopup" :active.sync="addProductPopup" title="Add Product" @close="handleCloseStorePopup">
        <div class="relative px-2">
          <label for="selectedProducts">Products</label>
          <multiselect
            id="selectedProducts"
            class="custom-multiselect"
            v-model="productIds"
            :options="products"
            :multiple="true"
            :taggable="true"
            :hideSelected="true"
            tag-placeholder="Add a product"
            label="name"
            track-by="_id"
            placeholder="Add a product"
            @search-change="customFilter"
            :max-height="200"
          >
          </multiselect>
          <vs-icon
            v-if="productIds.length"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="productIds = []">
          </vs-icon>
        </div>
        <vs-button
          @click="addProduct"
          color="primary"
          id='confirmRequest'
          class="m-2"
          type="border"
          >Add</vs-button
        >
      </vs-popup>
      <!-- <span class="mr-2">
        {{
        dataTableParams.page * dataTableParams.limit -
        (dataTableParams.limit - 1)
        }}
        -
        {{
        totalDocs - dataTableParams.page * dataTableParams.limit > 0
        ? dataTableParams.page * dataTableParams.limit
        : totalDocs
        }}
        of {{ totalDocs }}
      </span>
      <div class="pagination-div" v-if="serverResponded">
        <paginate
          :page-count="totalPage"
          :click-handler="handleChangePage"
          class="pagination"
          :page-range="9"
          :prevText="'<'"
          :nextText="'>'"
        ></paginate>
      </div> -->
    </div>
  </vx-card>
</template>

<script>
  import vSelect from 'vue-select';
  import { mapActions } from 'vuex';
  import draggable from "vuedraggable";
  import _ from "lodash";
  import Multiselect from "vue-multiselect";
  import 'vue-multiselect/dist/vue-multiselect.min.css';

  export default {
    name: 'ProductListByCategory',
    components: {
      vSelect,
      draggable,
      Multiselect,
    },
    props:{
      categoryId: {
        type: String
      }
    },
    data() {
      return {
        id: this.categoryId,
        isMounted: false,
        currentPage: 1,
        totalDocs: 0,
        page: 1,
        noDataText: 'No products found',
        dataTableParams: {
          search: "",
          sort: "createdAt",
          dir: "desc",
          page: 1,
          limit: 10
        },
        limitOptions: [
          { text: "5", value: 5 },
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          { text: "100", value: 100 }
        ],
        serverResponded: false,
        awaitingSearch: null,
        productData: '',
        popUpDelete: false,
        popupData: {
          id: ''
        },
        isDragDisabled: false,
        addProductPopup: false,
        products: [],
        productIds: [],
        storeProductsParams: {
          search: "",
        },
        isParentCategory: false
      };
    },
    methods: {
      ...mapActions("category", [
        "getCategoryProductList",
        "removeProductFromCategory",
        "getCategoryDetails"
      ]),
      ...mapActions("admin", ["sortCategoryProductList"]),
      ...mapActions("storeProducts", [
        "fetchProducts",
        "addProductsToCategory",
        "fetchProductsNotInCategory"
      ]),
      handleCloseStorePopup() {
        this.addProductPopup = false;
        this.productIds = [];
        this.storeProductsParams.search = "";
        this.getStoreProducts();
      },
      customFilter: _.debounce(function (data) {
          this.storeProductsParams.search = data.target.value;
          this.getStoreProducts();
      }, 500),
      async addProduct() {
        try {
          if (!this.productIds.length) {
            return this.$vs.notify({
              title: "Add Product",
              text: "Please add a product(s)",
              color: "danger"
            });
          }
          this.$vs.loading({
            text: "Adding products...",
            color: "#3dc9b3",
            background: "white",
            container: "#addProductPopup",
            scale: 0.6,
          });
          const formattedProductIds = this.productIds.map(p => p._id);
          const payload = {
            categoryId: this.id,
            productIds: formattedProductIds
          }
          const res = await this.addProductsToCategory(payload);
          if (res.status === 200) {
            this.productIds = [];
            this.addProductPopup = false;
            this.fetchProductListByCategory();
          }
          this.$vs.loading.close("#addProductPopup > .con-vs-loading");
        } catch (error) {
          this.$vs.loading.close("#addProductPopup > .con-vs-loading");
          console.error("ERR: ", error);
        }
      },
      async getStoreProducts() {
        try {
          const payload = {
            search: this.storeProductsParams.search,
            categoryId: this.id,
          }
          const res = await this.fetchProductsNotInCategory(payload);
          if (res.status === 200) {
            this.products = res.data.data;
          }
        } catch (error) {
          console.error("ERR: ", error);
        }
      },
      handleRedirectToProduct(id){
        let editRoute = `/super-admin/store/products/${id}/edit`;
        if (this.loggedInUserType === 'admin') editRoute = `/admin/store/products/${id}/edit`;
        return editRoute;
      },
      handleFieldSort:_.debounce(async function (data, index) {
        const newOrder = parseInt(data.productDetails.orderId);
        if (!isNaN(newOrder)) {
          data.productDetails.orderId = newOrder;

          const inputElement = this.$refs.orderInput[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.blur();
          }

          const payload = {
            categoryId: this.id,
            productId: data.productDetails._id,
            orderId: data.productDetails.orderId
          }
          await this.updateOrderNumber([payload]);
        }
      }, 1000),
      async updateOrderNumber(props){
        
        if(props){
          this.$vs.loading({
            text: "Sorting...",
            color: "#3dc9b3",
            background: "white",
            container: "#productCategoryTable",
            scale: 0.6,
          });
          const products = props.length === 1 ? props : props.map((item, index) => {
            return {
              categoryId: this.id,
              productId: item.productDetails._id,
              orderId: parseInt(index+1),
            }
          });
          const payload = {
            categoryId: this.id,
            products
          }
          const res = await this.sortCategoryProductList(payload);
          this.fetchProductListByCategory();
          if(res.status === 200){
            this.$vs.notify({
              title: "Success",
              text: "Product Order Updated",
              color: "success",
            });
          }
          this.$vs.loading.close("#productCategoryTable > .con-vs-loading");
        }
      },
      async fetchProductListByCategory(){
        try {
          this.$vs.loading();
          const info = {
            dataTableParams: this.dataTableParams,
            id: this.id
          };
          const res = await this.getCategoryProductList(info);
          if (res.status === 200){
            this.productData = res.data.data;
          }
          this.$vs.loading.close();
        } catch (error) {
          this.$vs.loading.close();
          console.error("ERR: ", error);
        }
      },
      handleSearch(searching) {
        if (this.awaitingSearch) {
          clearTimeout(this.awaitingSearch);
          this.awaitingSearch = null;
        }
        if (searching) {
          this.isDragDisabled = true;
        } else {
          this.isDragDisabled = false;
        }
        this.awaitingSearch = setTimeout(() => {
          this.serverResponded = false;
          this.dataTableParams.search = searching;
          this.$refs.table.currentx = 1;
          this.fetchProductListByCategory();
        }, 500);
      },
      handleChangePage(page) {
        this.dataTableParams.page = page;
        this.fetchProductListByCategory();
      },
      handleSort(key, active) {
        this.serverResponded = false;
        this.$refs.table.currentx = 1;
        this.fetchProductListByCategory();
      },
      openDeletePopup(id){
        this.popupData = id;
        this.popUpDelete=true;
      },
      async fetchCategoryDetails(id) {
        this.$vs.loading();
        try {
          const res = await this.getCategoryDetails(id)
          this.isParentCategory = res.data.data.subCategoryDetails.length > 0 ? true : false;
          this.$vs.loading.close();
        }
        catch(err){
          this.$vs.loading.close();
          console.log(err)
        }
      },
      async deleteDetailHandler(productData) {
        const data = {
          categoryId: this.id,
          productId: productData._id
        };
        await this.removeProductFromCategory(data)
          .then(res => {
            this.popUpDelete=false;
            this.fetchProductListByCategory();
            this.$vs.notify({
              title: "Success",
              text: "Product Removed successfully.",
              color: "success"
            });
          })
          .catch(err => {
            this.popUpDelete = false;
            this.$vs.notify({
              title: err.data.title,
              text: err.data.message,
              color: "danger"
            });
          })
      },
    },
    watch: {
      "dataTableParams.search": function(newVal, oldVal) {
        this.handleSearch(newVal);
      },
    //   "dataTableParams.page": function(newVal, oldVal) {
    //     if (newVal !== oldVal) {
    //       this.dataTableParams.page = newVal;
    //       this.fetchProductListByCategory(this.id);
    //     }
    //   },
    //   "dataTableParams.limit": function(newlimit, oldLimit) {
    //     if (newlimit !== oldLimit) {
    //       this.serverResponded = false;
    //       this.dataTableParams.page = 1;
    //       this.dataTableParams.limit = newlimit;
    //       this.fetchProductListByCategory(this.id);
    //     }
    //   }
    },
    computed: {
      totalPage: function() {
        return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
          ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
          : parseInt(this.totalDocs / this.dataTableParams.limit);
      }
    },
    async created() {
      this.id = this.categoryId;
      this.dataTableParams.categoryId = this.categoryId;
      await this.fetchCategoryDetails(this.categoryId)
      await this.fetchProductListByCategory();
      await this.getStoreProducts();
    }
  };
</script>

<style>
.no-spin input[type="number"]::-webkit-inner-spin-button,
.no-spin input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.removeSelectedFilters{
  position: absolute;
  right: 28px;
  bottom: 12px;
  cursor: pointer;
}
.custom-multiselect .multiselect__tag, .multiselect__option--highlight{
  background-color: #3CC9B2;
  color: white;
}
.custom-multiselect .multiselect__content-wrapper {
  max-height: 200px;
  overflow-y: auto;
  position: fixed !important;
  z-index: 99999;
  width: 100%;
  max-width: 567px;
}
</style>
